import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';

import { Hero } from '@latitude/hero';
import Section from '@latitude/section';
import { ALIGN, BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { ListNumbered } from '@latitude/list-numbered';
import { Heading4 } from '@latitude/heading';
import { NotificationBanner } from '@latitude/banner';

import BalanceTransferFaqSection from './_balance-transfer-faq';
import BalanceTransferInfoSection from './_balance-transfer-info';
import Layout from '@/components/layout';
import heroImage from './images/hero-balance-transfer.webp';
import imgOffer from './images/promo-balance-transfer.webp';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { selectDataSourceForAlertBanner } from '@/utils/helpers';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import { useSetState } from '@/utils/hooks';

const HOW_TO_APPLY_STEPS = [
  'Visit Latitude Service Centre by clicking the Log in button in the top right corner or open the Latitude App. (Please ensure you download the latest version)',
  'Log in using your Latitude ID.',
  'Select the Balance Transfer tab and fill in the form.',
  "If you don't have a Latitude ID, simply register from the login page and follow the steps above.",
  "<div>For more information on Terms and Conditions <a href='https://assets.gemfinance.co.nz/legals/terms-conditions/balance-transfer-termsconds.pdf' target='_blank'>click here</a>.</div>"
];
const IMPORTANT_INFO = [
  "<sup id='note-star'>*</sup>&nbsp;Balance transfer applies to eligible non-Latitude New Zealand issued credit cards only. Balance transfer amounts commencing from $500 or more, up to 85% of your Gem Visa credit limit. Monthly payments required (exact amounts specified in your statement). Paying only the minimum monthly payment may not pay out the balance transfer amount before the end of the promotional period. If there is an outstanding balance after the promotional period, interest will be charged at the current annual interest rate for cash advances, currently 29.95% (subject to change).",
  'Offer only available to existing customers who have submitted an application form. Eligibility criteria apply. Subject to approval.',
  "<sup id='note-hat'>^</sup>&nbsp;Gem Visa T&Cs and fees apply including a $65 annual fee (charged $32.50 half-yearly). Interest rate currently 29.49% p.a. applies after interest free term ends. For cash advances, an interest rate of 29.95% p.a. and a $2 fee applies. Further information on rates and fees can be found at gemfinance.co.nz. Available on participating Gem Visa credit cards only.",
  'Credit provided by Latitude Financial Services Ltd.'
];

function BalanceTransferPage({ location }) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: []
  });

  useEffect(() => {
    let [heroBanners, alerts] = [[], []];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData:
        heroBanners.length > 0 ? heroBanners : state.heroBannerData
    });
  }, [contentfulPageData]);
  /** - END - */
  return (
    <Layout>
      <main className="navigation-spacer gem-visa-page">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/credit-cards/gem-visa-card/balance-transfer/"
          />
          <title>
            Gem Visa Credit Card | Interest Free Credit Cards NZ | Gem by
            Latitude
          </title>
          <meta
            name="description"
            content="Enjoy 6 months int. free on all Gem Visa purchases of $250 and over. T&amp;Cs and $65 annual (charged $32.50 half-yearly) fees apply. Int. rate 29.99% p.a. applies after int. free term ends."
          />
        </Helmet>
        {!selectDataSourceForAlertBanner(contentfulPageData) && (
          <NotificationBanner siteName="gemfinance-co-nz" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <Hero
            css={`
              && {
                background: #0146aa;
                background: linear-gradient(135deg, #0146aa 0%, #0162ed 100%);
                color: #fff;

                h1,
                div,
                p {
                  color: #fff;
                }

                .HeroContent {
                  [class^='Linkstyled__StyledLink-'] {
                    color: #0061ee !important;
                    background-color: #fff !important;
                    border-color: transparent !important;

                    &:hover {
                      box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 13px 0px;
                      transition: all 150ms ease-in-out 0s;
                      border-bottom: 1px solid rgb(0, 106, 255);
                      text-shadow: rgb(0, 106, 255) 0px 0px 1px;
                      background-color: #cee6ff !important;
                      border-color: #cee6ff;
                    }
                  }
                }

                @media (max-width: ${BREAKPOINT.MD}) {
                  h1,
                  div,
                  p {
                    color: #fff !important;
                  }

                  .HeroContent {
                    padding: 10px;
                    background-color: transparent;
                  }
                }
              }
            `}
            imageContent={
              <div
                id="hero"
                css={`
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    justify-content: flex-end;
                  }
                `}
              >
                <img
                  src={heroImage}
                  css={`
                    height: 250px;
                    width: auto;
                    margin: 0;
                    @media (min-width: ${BREAKPOINT.MD}) {
                      height: 400px;
                      margin: 70px -60px 0 0;
                    }
                    @media (min-width: ${BREAKPOINT.LG}) {
                      height: 350px;
                      margin: 0 -90px 0 0;
                    }
                  `}
                  alt="Low Rate Card"
                />
              </div>
            }
            title={
              'Get 6.99% p.a. for 6 months on balance transfers.<sup>*</sup>'
            }
            text={null}
            footnote={[
              'Visit the Latitude Service Centre or Latitude App to apply.'
            ]}
          />
        )}

        <Section id="how-to-apply">
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            How to apply
          </Heading4>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              margin: '10pt auto 10pt auto',
              padding: '5pt'
            }}
          >
            <div css={{ width: '350px', height: 'auto' }}>
              <img src={imgOffer} alt="Balance Transfer Offer" />
            </div>
            <div
              css={{
                maxWidth: '500px',
                padding: '0 10pt'
              }}
            >
              <ListNumbered
                data={HOW_TO_APPLY_STEPS}
                viewStyle="compact"
                counterBgColor={COLOR.BLUE_DEEP}
                counterVerticalAlign
                separator="none"
              />
            </div>
          </div>
        </Section>

        <BalanceTransferFaqSection anchor="faqs" />
        <BalanceTransferInfoSection
          anchor="important-Info"
          data={IMPORTANT_INFO}
        />
      </main>
    </Layout>
  );
}

export default BalanceTransferPage;
